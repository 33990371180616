const getRegion = (province) => {
    let map_data = {
        "อำนาจเจริญ": "ภาคตะวันออกเฉียงเหนือ",
        "บึงกาฬ": "ภาคตะวันออกเฉียงเหนือ",
        "บุรีรัมย์": "ภาคตะวันออกเฉียงเหนือ",
        "ชัยภูมิ": "ภาคตะวันออกเฉียงเหนือ",
        "กาฬสินธุ์": "ภาคตะวันออกเฉียงเหนือ",
        "ขอนแก่น": "ภาคตะวันออกเฉียงเหนือ",
        "เลย": "ภาคตะวันออกเฉียงเหนือ",
        "มหาสารคาม": "ภาคตะวันออกเฉียงเหนือ",
        "มุกดาหาร": "ภาคตะวันออกเฉียงเหนือ",
        "นครพนม": "ภาคตะวันออกเฉียงเหนือ",
        "นครราชสีมา": "ภาคตะวันออกเฉียงเหนือ",
        "หนองบัวลำภู": "ภาคตะวันออกเฉียงเหนือ",
        "หนองคาย": "ภาคตะวันออกเฉียงเหนือ",
        "ร้อยเอ็ด": "ภาคตะวันออกเฉียงเหนือ",
        "สกลนคร": "ภาคตะวันออกเฉียงเหนือ",
        "ศรีสะเกษ": "ภาคตะวันออกเฉียงเหนือ",
        "สุรินทร์": "ภาคตะวันออกเฉียงเหนือ",
        "อุบลราชธานี": "ภาคตะวันออกเฉียงเหนือ",
        "อุดรธานี": "ภาคตะวันออกเฉียงเหนือ",
        "ยโสธร": "ภาคตะวันออกเฉียงเหนือ",
        "เชียงใหม่": "ภาคเหนือ",
        "เชียงราย": "ภาคเหนือ",
        "ลำปาง": "ภาคเหนือ",
        "ลำพูน": "ภาคเหนือ",
        "แม่ฮ่องสอน": "ภาคเหนือ",
        "น่าน": "ภาคเหนือ",
        "พะเยา": "ภาคเหนือ",
        "แพร่": "ภาคเหนือ",
        "อุตรดิตถ์": "ภาคเหนือ",
        "ตาก": "ภาคเหนือ",
        "สุโขทัย": "ภาคเหนือ",
        "พิษณุโลก": "ภาคเหนือ",
        "พิจิตร": "ภาคเหนือ",
        "กำแพงเพชร": "ภาคเหนือ",
        "เพชรบูรณ์": "ภาคเหนือ",
        "นครสวรรค์": "ภาคเหนือ",
        "อุทัยธานี": "ภาคเหนือ",
        "อ่างทอง": "ภาคกลาง",
        "ชัยนาท": "ภาคกลาง",
        "พระนครศรีอยุธยา": "ภาคกลาง",
        "กรุงเทพมหานคร": "ภาคกลาง",
        "ลพบุรี": "ภาคกลาง",
        "นครปฐม": "ภาคกลาง",
        "นนทบุรี": "ภาคกลาง",
        "ปทุมธานี": "ภาคกลาง",
        "สมุทรปราการ": "ภาคกลาง",
        "สมุทรสาคร": "ภาคกลาง",
        "สมุทรสงคราม": "ภาคกลาง",
        "สระบุรี": "ภาคกลาง",
        "สิงห์บุรี": "ภาคกลาง",
        "สุพรรณบุรี": "ภาคกลาง",
        "นครนายก": "ภาคกลาง",
        "ฉะเชิงเทรา": "ภาคกลาง",
        "จันทบุรี": "ภาคกลาง",
        "ชลบุรี": "ภาคกลาง",
        "ปราจีนบุรี": "ภาคกลาง",
        "ระยอง": "ภาคกลาง",
        "สระแก้ว": "ภาคกลาง",
        "ตราด": "ภาคกลาง",
        "กาญจนบุรี": "ภาคกลาง",
        "ราชบุรี": "ภาคกลาง",
        "เพชรบุรี": "ภาคกลาง",
        "ประจวบคีรีขันธ์": "ภาคกลาง",
        "ชุมพร": "ภาคใต้",
        "นครศรีธรรมราช": "ภาคใต้",
        "นราธิวาส": "ภาคใต้",
        "ปัตตานี": "ภาคใต้",
        "พัทลุง": "ภาคใต้",
        "สงขลา": "ภาคใต้",
        "สุราษฎร์ธานี": "ภาคใต้",
        "ยะลา": "ภาคใต้",
        "กระบี่": "ภาคใต้",
        "พังงา": "ภาคใต้",
        "ภูเก็ต": "ภาคใต้",
        "ระนอง": "ภาคใต้",
        "สตูล": "ภาคใต้",
        "ตรัง": "ภาคใต้",
    }
    return map_data[province]
}

export default {
    getRegion
}
