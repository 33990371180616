import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layout from '../components/Layout';
import Mapbox from '../mapbox'
import LegendCard from '../mapbox/LegendCard'
import Calculate from '../util/calculate';

import { Select } from 'antd';
import './index.css';
import imageStudentsKRTHelp from '/images/targetgroup-11.png';
import imageKRT3 from '/images/targetgroup-9.png';
import ImageBG from '/images/thailand-map.jpg';

import imageMapKRT from '/images/targetgroup-8.png';
import imageSchoolKRT from '/images/targetgroup-10.png';
import target_vec from '/images/targetgroup-1.png'
import target_vec_1 from '/images/targetgroup-2.png'
import target_vec_2 from '/images/targetgroup-3.png'
import target_vec_3 from '/images/targetgroup-4.png'
import target_vec_4 from '/images/targetgroup-5.png'
import target_vec_5 from '/images/targetgroup-6.png'
import imagePaper from '/images/targetgroup-7.png'

import Star from '/images/organization.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TumbonAddr from '../util/TumbonAddr'
import region from '../util/region';
import RefDataDate from '../components/RefDataDate';
import useTranslation from '../hook/useTranslation'
import api from '../util/api';
import getProvinceName from '../util/getProvinceName'

const { Option } = Select;

const PageTargetGroup = (props) => {
  const [data, setData] = React.useState({

  });
  const [initData, setInitData] = React.useState(null)
  const [filter, setFilter] = React.useState({
    minView: 'country',
    view: 'country',
    year: "2564",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);
  const [T, setLang, lang] = useTranslation()
  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(async () => {
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    let res_target_group = await api.getInitData('target_group')
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        let {
          coverProvince2564,
          organizationJoin2564,
          project2564,
          helpStudents2562,
          helpStudents2563,
          helpStudents2564,
        } = res_target_group.deepdata.province[getProvinceName(pid)]
        let max = Math.max(helpStudents2562, helpStudents2563, helpStudents2564)
        data['coverProvinceAll'] = coverProvince2564
        data['projectjoin'] = project2564.length
        data['organizationjoin'] = organizationJoin2564
        data['target2562'] = helpStudents2562
        data['target2563'] = helpStudents2563
        data['target2564'] = helpStudents2564
        data['percentY2562'] = helpStudents2562 / max * 80
        data['percentY2563'] = helpStudents2563 / max * 80
        data['percentY2564'] = helpStudents2564 / max * 80
        setData({ ...data })
        setInitData({ 
          ...res_target_group.deepdata.province[getProvinceName(pid)],
          organization2562: res_target_group.deepdata.organization2562.filter(item => item.province_name === getProvinceName(pid)),
          organization2563: res_target_group.deepdata.organization2563.filter(item => item.province_name === getProvinceName(pid)),
          organization2564: res_target_group.deepdata.organization2564.filter(item => item.province_name === getProvinceName(pid)),
          organization2565: res_target_group.deepdata.organization2565.filter(item => item.province_name === getProvinceName(pid)),
         })
        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
      } else {
        let {
          coverProvince2564,
          organizationJoin2564,
          project2564,
          helpStudents2562,
          helpStudents2563,
          helpStudents2564,
        } = res_target_group.deepdata
        let max = Math.max(helpStudents2562, helpStudents2563, helpStudents2564)
        data['coverProvinceAll'] = coverProvince2564
        data['projectjoin'] = project2564.length
        data['organizationjoin'] = organizationJoin2564
        data['target2562'] = helpStudents2562
        data['target2563'] = helpStudents2563
        data['target2564'] = helpStudents2564
        data['percentY2562'] = helpStudents2562 / max * 80
        data['percentY2563'] = helpStudents2563 / max * 80
        data['percentY2564'] = helpStudents2564 / max * 80
        setData({ ...data })
        setInitData({ ...res_target_group.deepdata })
      }
    }
  }, [])

  // useEffect(async () => {
  //   let res_target_group = await api.getInitData('target_group')
  //   let {
  //     coverProvince2564,
  //     organizationJoin2564,
  //     project2564,
  //     helpStudents2562,
  //     helpStudents2563,
  //     helpStudents2564,
  //   } = res_target_group.deepdata
  //   let max = Math.max(helpStudents2562, helpStudents2563, helpStudents2564)
  //   data['coverProvinceAll'] = coverProvince2564
  //   data['projectjoin'] = project2564.length
  //   data['organizationjoin'] = organizationJoin2564
  //   data['target2562'] = helpStudents2562
  //   data['target2563'] = helpStudents2563
  //   data['target2564'] = helpStudents2564
  //   data['percentY2562'] = helpStudents2562 / max * 80
  //   data['percentY2563'] = helpStudents2563 / max * 80
  //   data['percentY2564'] = helpStudents2564 / max * 80
  //   setData({ ...data })
  //   setInitData({ ...res_target_group.deepdata })
  // }, []);

  useEffect(async () => {
    if (initData) {
      let dataContextUniversity = initData['organization' + filter.year]
      if (filter.region !== 'ทั้งหมด') dataContextUniversity = dataContextUniversity.filter(item => item.region === filter.region)
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter['view']
      }, convertMapContent(filter['view'], dataContextUniversity))
      let data_organization = [...new Set(dataContextUniversity)]
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
          data_organization = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
        } else {
          data_organization = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name))]
        }
      }
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        { id: 'organization', layer: 'marker', data: data_organization.map(item => ({ ...item, type: 'organization' })), icon: Star },
      ])

      let data_legend = []

      if (lang === 'EN') {
        data_legend = [
          { name: 'Number of students who received scholarships', value: response.data.reduce((acc, cur) => acc + cur.target_group, 0), unit: 'students' },
          { name: 'Number of learning management units participating in the project', value: response.data.reduce((acc, cur) => acc + cur.organize_count, 0), unit: 'organizations' },
          { name: 'Number of project', value: response.data.reduce((acc, cur) => acc + cur.project_count, 0), unit: 'projects' },
        ]
      } else {
        data_legend = [
          { name: 'จำนวนนักเรียนที่ได้รับทุน', value: response.data.reduce((acc, cur) => acc + cur.target_group, 0), unit: 'คน' },
          { name: 'จำนวนหน่วยจัดการเรียนรู้ที่เข้าร่วม', value: response.data.reduce((acc, cur) => acc + cur.organize_count, 0), unit: 'องค์กร' },
          { name: 'จำนวนโครงการ', value: response.data.reduce((acc, cur) => acc + cur.project_count, 0), unit: 'โครงการ' },
        ]
      }

      setLegendData({
        ...legend_data,
        title: T(filter.province_name, lang),
        subtitle: T(filter.city_name, lang),
        data: data_legend,
      })
    }
  }, [filter, lang, initData])

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { region: region.getRegion(item), province: item, total: 0, target_group: 0, organize_count: 0, project_count: 0 } });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], total: 0, target_group: 0, organize_count: 0, project_count: 0 } });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], total: 0, target_group: 0, organize_count: 0, project_count: 0 } });
    data.forEach((item) => {
      let total = (item.sum_target_group)
      let count = (item.sum_target_group)
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (index !== -1) {
          !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
          !province[index]['target_group'] ? province[index]['target_group'] = count : province[index]['target_group'] += count
          !province[index]['organize_count'] ? province[index]['organize_count'] = 1 : province[index]['organize_count'] += 1
          !province[index]['project_count'] ? province[index]['project_count'] = item.project_group.length : province[index]['project_count'] += item.project_group.length
        }
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          !city[index]['total'] ? city[index]['total'] = total : city[index]['total'] += total
          !city[index]['target_group'] ? city[index]['target_group'] = count : city[index]['target_group'] += count
          !city[index]['organize_count'] ? city[index]['organize_count'] = 1 : city[index]['organize_count'] += 1
          !city[index]['project_count'] ? city[index]['project_count'] = item.project_group.length : city[index]['project_count'] += item.project_group.length
        }
        res = city
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['target_group'] ? tumbon[index]['target_group'] = count : tumbon[index]['target_group'] += count
          !tumbon[index]['organize_count'] ? tumbon[index]['organize_count'] = 1 : tumbon[index]['organize_count'] += 1
          !tumbon[index]['project_count'] ? tumbon[index]['project_count'] = item.project_group.length : tumbon[index]['project_count'] += item.project_group.length
        }
        res = tumbon
      } else if (layer === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['target_group'] ? tumbon[index]['target_group'] = count : tumbon[index]['target_group'] += count
          !tumbon[index]['organize_count'] ? tumbon[index]['organize_count'] = 1 : tumbon[index]['organize_count'] += 1
          !tumbon[index]['project_count'] ? tumbon[index]['project_count'] = item.project_group.length : tumbon[index]['project_count'] += item.project_group.length
        }
        res = tumbon
      }
    })
    if (filter.region !== 'ทั้งหมด' && filter.view === 'country') res = res.filter(item => item.region === 'ภาค' + filter.region)
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    console.log(filter)
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['target_group'] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['target_group'] / result.map(item => item.target_group).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      // { color: '#FF9A7B', value: (average(result) * 1.5), count: 0, text: `มากกว่า ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FFAE93', value: (average(result)), count: 0, text: `${average(result).toFixed(2)}% - ${(average(result) * 1.5).toFixed(2)}%` },
      // { color: '#FECAB5', value: (average(result) / 2), count: 0, text: `${(average(result) / 2).toFixed(2)}% - ${average(result).toFixed(2)}%` },
      // { color: '#FFEEE7', value: (0), count: 0, text: `น้อยกว่า ${(average(result) / 2).toFixed(2)}%` },

      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.target_group)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.target_group)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.target_group)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.target_group)
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
      result = result.filter(item => item.province === filter.province)
    }
    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  return (
    <Layout
      Role={['public-login', 'partner', 'eef', 'partner_province']}
      backgroundImage={`url(${ImageBG})`}
      getLang={(translate, lang) => {
        setLang(lang)
      }}
    >

      <div>
        <div className='h-[120px]'></div>

        <h1 className="font-bold text-2xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("TG_Project_TITLE")}</h1>
        <div className="max-w-[1300px] 2xl:max-w-[1400px] rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("Project_Description")}</div>

          <p className="text-xl mb-2 font-baijam">{T("TG_Project_Description")}</p>

          <div className="grid grid-cols-11 gap-4 w-full mt-8">
            <div className="col-span-12 md:col-span-9 lg:col-span-5 ">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("Project_Description")}</div>
              < div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full md:h-[210px] p-0 md:p-1" >
                <div className="px-6 py-4">
                  <p className="flex text-lg m-0">{T("TG_Target_Group")}</p>
                  < div className='flex flex-row justify-around ' >
                    <div className="flex flex-col m-2">
                      <img className="flex self-center" src={target_vec} alt="image for banner" width={'84px'} style={{}} />
                    </div>
                    <div className="flex-col self-center">
                      <div className="text-md mb-2 font-bold">
                        <ul >
                          <li> – {T("TG_AGE_15")}</li>
                          <li> – {T("TG_EDU_LEVEL")}</li>
                          <li> – {T("TG_LESS_INCOME_6500")}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-9 lg:col-span-6">
              <div className="font-bold text-xl mb-2 text-[#0E3165] mt-8 md:mt-0">{T("TG_Support_EEF")}</div>
              < div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full lg:h-[210px] p-0 lg:p-1" >
                <div class="grid grid-cols-12 gap-4 p-2">

                  <div class="col-span-12 lg:col-span-6">
                    <div className='flex flex-row'>
                      <img className="flex self-center mr-2" src={target_vec_1} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <div className="text-sm text-black-500">{T("TG_commucommunity_inovation_development")}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 lg:col-span-6">
                    <div className='flex flex-row'>
                      <img className="flex self-center mr-2" src={target_vec_2} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <div className="text-sm text-black-500">{T("TG_commucommunity_career_opportunities")}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 lg:col-span-6">
                    <div className='flex flex-row '>
                      <img className="flex self-center mr-2" src={target_vec_3} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <div className="text-sm text-black-500">{T("TG_commucommunity_access")}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 lg:col-span-6">
                    <div className='flex flex-row'>
                      <img className="flex self-center mr-2" src={target_vec_4} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <div className="text-sm text-black-500">{T("TG_commucommunity_knowledge")}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-12 lg:col-span-6">
                    <div className='flex flex-row '>
                      <img className="flex self-center mr-2" src={target_vec_5} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <div className="text-sm text-black-500">{T("TG_commucommunity_analysis")}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-12 lg:col-span-6">
                    <div className='flex flex-row'>
                      <img className="flex self-center mr-2" src={imagePaper} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <div className="text-sm text-black-500">{T("TG_commucommunity_research")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="font-bold text-xl mt-20 lg:mt-8 mb-2 text-[#0E3165]">{T("TG_OUTCOMES_PROJECT_2563")}</div>
          <div className="grid grid-cols-12 gap-4 w-full">
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[120px] lg:h-[120px] p-1 mb-4">

                <div className='flex flex-row justify-start lg:justify-center px-2 lg:px-0'>
                  <img className="flex self-center" src={imageMapKRT} alt="image for banner" width={'72px'} />
                  {
                    lang === 'EN' ?
                      <div className="flex flex-col ml-4 py-3">
                        <div className="font-bold text-md md:text-lg m-0 mr-1">coverage in</div>
                        <div className="font-bold text-xl m-0 text-[#048967]">{`${data?.coverProvinceAll?.toLocaleString('en-US')}`}</div>
                        <div className="font-bold text-md md:text-lg m-0">provinces</div>
                      </div>
                      :
                      <div className="flex flex-col ml-4 py-3">
                        <div className="font-bold text-md md:text-lg m-0 mr-1">ครอบคลุม</div>
                        <div className="font-bold text-xl m-0 text-[#048967]">{`${data?.coverProvinceAll?.toLocaleString('en-US')}`}</div>
                        <div className="font-bold text-md md:text-lg m-0">จังหวัด</div>
                      </div>
                  }
                </div>
              </div>

              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[120px] lg:h-[120px] p-1">
                <div className='flex flex-row justify-start lg:justify-center px-2 lg:px-0 py-3'>
                  <img className="flex self-center" src={imageSchoolKRT} alt="image for banner" width={'72px'} />
                  {
                    lang === 'EN' ?
                      <div className="flex flex-col ml-4 py-3">
                        <p className="font-bold text-xl m-0 text-[#048967]">{`${data?.projectjoin?.toLocaleString('en-US')}`}  </p>
                        <p className="font-bold text-lg m-0">projects</p>
                      </div>
                      :
                      <div className="flex flex-col ml-4">
                        <p className="font-bold text-md md:text-lg m-0">จำนวนโครงการ</p>
                        <p className="font-bold text-xl m-0 text-[#048967]">{`${data?.projectjoin?.toLocaleString('en-US')}`}</p>
                        <p className="font-bold text-md md:text-lg m-0">โครงการ</p>
                      </div>
                  }
                </div>
              </div>

            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[120px] lg:h-[120px] p-1  mb-4">
                <div className='flex flex-row justify-start lg:justify-center px-2 lg:px-0 py-3'>
                  <img className="flex self-center" src={imageKRT3} alt="image for banner" width={'72px'} />
                  {
                    lang === 'EN' ?
                      <div className="flex flex-col ml-4 py-3">
                        <div className="font-bold text-lg m-0 mr-1">{`${data?.organizationjoin?.toLocaleString('en-US')}`} learning management units participating in the project</div>
                      </div>
                      :
                      <div className="flex flex-col ml-4">
                        <p className="font-bold text-md md:text-lg m-0">หน่วยจัดการเรียนรู้ที่เข้าร่วม</p>
                        <p className="font-bold text-xl m-0 text-[#048967]">{`${data?.organizationjoin?.toLocaleString('en-US')}`}</p>
                        <p className="font-bold text-md md:text-lg m-0">องค์กร</p>
                      </div>
                  }

                </div>
              </div>

              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[120px] lg:h-[120px] p-1">
                <div className='flex flex-row justify-start lg:justify-center px-2 lg:px-0 py-3'>
                  <img className="flex self-center" src={imageStudentsKRTHelp} alt="image for banner" width={'72px'} />

                  {
                    lang === 'EN' ?
                      <div className="flex flex-col ml-4 py-3">
                        <div className="font-bold text-lg m-0 mr-1">{`${data?.target2564?.toLocaleString('en-US')}`} students</div>
                        <div className="font-bold text-lg m-0 mr-1"> who received scholarships </div>
                      </div>
                      :
                      <div className="flex flex-col ml-4">
                        <p className="font-bold text-md md:text-lg m-0">ผู้ได้รับทุน</p>
                        <p className="font-bold text-xl m-0 text-[#048967]">{`${data?.target2564?.toLocaleString('en-US')}`}</p>
                        <p className="font-bold text-md md:text-lg m-0">คน</p>
                      </div>
                  }

                </div>
              </div>
            </div>


            <div className="col-span-12 lg:col-span-6">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[270px] lg:h-[250px] p-1">
                <div className="px-6 py-4">
                  <div className="flex flex-col ml-4">
                    <div className="font-bold text-xl mb-2 text-center">{T("NO_STUDENTS_SCHOLARSHIPS_AY_1")}</div>
                  </div>
                  <div className='border-t-2 w-full h-[0.5px]'></div>
                  <div className="flex flex-col">

                    < div className="font-bold text-lg lg:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2564")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2564 * 0.85 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-500 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2564?.toLocaleString('en-US')}`} {T('persons')}</div>
                    </div>

                    < div className="font-bold text-lg lg:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2563")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2563 * 0.85 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-500 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2563?.toLocaleString('en-US')}`} {T('persons')}</div>
                    </div>


                    < div className="font-bold text-lg lg:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2562")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2562 * 0.85 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-400 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2562?.toLocaleString('en-US')}`} {T('persons')}</div>
                    </div>



                  </div>

                </div>
              </div>
            </div>


          </div>


          <div className="max-w rounded-xl overflow-hidden shadow-card bg-white w-full mt-10 p-2">

            <header className="text-gray-600 body-font">
              <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                </nav>
                <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2 text-[#048967] text-center">
                  {T("TG_MAP")}
                </p>
                <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                  {
                    lang !== 'EN' &&
                    <button class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                      onClick={() => navigate('/fullmap-targetgroup')}>
                      สำรวจข้อมูลโครงการ
                      <svg fill="none" stroke="currentColor" s troke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  }
                </div>
              </div >
            </header >

            <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>
            < div className="flex flex-col md:flex-row mb-2" >
              <div>
                <h5 className="text-sm m-0 ml-[9px]" >{T("year")} : </h5>
                < Select
                  showSearch
                  className='new-design-select'
                  placeholder="ปีการศึกษา"
                  value={filter.year}
                  style={{ width: 180, margin: 4 }}
                  onChange={(val) => {
                    setFilter({ ...filter, year: val })
                  }}
                >
                  {
                    [
                      { value: '2564', text: T("Y2564") },
                      { value: '2563', text: T("Y2563") },
                      { value: '2562', text: T("Y2562") },

                    ]
                      .filter(x => x.value !== 'ไม่ระบุ')
                      .sort()
                      .map(x => <Option value={x.value}>{x.text}</Option>)
                  }
                </Select>
              </div>
              <div>
                <h5 className="text-sm m-0 ml-[9px]" >{T("region")} : </h5>
                < Select
                  showSearch
                  className='new-design-select'
                  placeholder="ภาค"
                  value={filter.region}
                  style={{ width: 180, margin: 4 }}
                  onChange={(val) => {
                    setFilter({ ...filter, region: val })
                  }}
                >
                  <Option value={'ทั้งหมด'}>{T('ทั้งหมด', lang)}</Option>
                  {
                    initData ?
                      [...new Set(initData['organization' + filter.year].map(x => x.region))]
                        .filter(x => x !== 'ไม่ระบุ')
                        .sort()
                        .map(x => <Option value={x}>{T('ภาค' + x, lang)}</Option>)
                      :
                      null
                  }
                </Select>
              </div>
              <div>
                <h5 className="text-sm m-0 ml-[9px]" >{T("province")} : </h5>
                <Select
                  className='new-design-select'
                  showSearch
                  placeholder="เลือกจังหวัด"
                  defaultValue="ทั้งหมด"
                  value={filter.province_name}
                  style={{ width: 180, margin: 4 }}
                  onChange={(val) => {
                    setFilter({ ...filter, view: (val === 'ทั้งหมด' ? 'country' : 'province'), province_name: val, city_name: null })
                  }}
                >
                  <Option value={'ทั้งหมด'}>{T('ทั้งหมด')}</Option>
                  {
                    initData ?
                      [...new Set(initData['organization' + filter.year].filter(item => filter.region !== 'ทั้งหมด' ? item.region === filter.region : true).map(x => x.province_name))]
                        .filter(x => x !== 'ไม่ระบุ')
                        .sort()
                        .map(x => <Option value={x}>{T(x)}</Option>)
                      :
                      null
                  }
                </Select>
              </div>
            </div>

            <div className="col-span-11">
              <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                <Mapbox
                  id={'map-krt'}
                  data={data_map}
                  lng={99}
                  lat={13.4}
                  zoom={5.2}
                  className="fullmap-cct-container"
                  style={"border_basic"}
                  filter={filter}
                  notic_title={'จำนวนโรงเรียนในประเทศไทย'}
                  lang={lang}
                  onclickBorder={onclickBorder}
                // onClickMarker={onClickMarker}
                />
                <div className='absolute top-[10px] left-[10px] z-1'>
                  <LegendCard {...legend_data} lang={lang} />
                  <div className='justify-left mt-2 mx-auto'>
                    {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                      <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> {T("back")}
                    </button> : <></>}
                  </div>
                </div>
                <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                  <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                    <span className='font-bold center mx-1 mb-2'>{T("PROPORTION_PERSON_RECEIVED_SCHOLARSHIP")}</span>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#1b5e20]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T("MAX")}
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#388e3c]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#66bb6a]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#a5d6a7]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T("MIN")}
                        </div>
                      </div>
                      <div className='ml-4  flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#94a3b8]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T("NO_DATA")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='absolute bottom-[10px] left-[470px] z-1'>
                  <div className='w-[400px] h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                    <span className='font-bold center mx-1 mb-2'>{T("LEARNING_MANAGEMENT_UNITS_PARTICIPATING_IN_THE_PROJECT")}</span>
                    <div class="flex flex-row">
                      <div class="flex flex-row text-center ml-2">
                        <img src={Star} style={{ width: '20px', height: '20px' }} />
                        <span className='center mx-1 mb-2'>{T("LEARNING_MANAGEMENT_UNITS_PARTICIPATING_IN_THE_PROJECT")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <RefDataDate
              title={T('TG_REF')}
              subjectTitle={T('PROCESSED_APR_2565')}
            />
          </div>
        </div>

        <div className='h-[120px]'></div>
      </div >
    </Layout >
  )
}

export default PageTargetGroup
